import { ItemType } from '@feeba/types'
import { ArrowRight, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material'
import { BoxProps, FormControl, IconButton, MenuItem, Select, Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SelectedComponent } from '../../../Models'
import { FeebaAccordion, FeebaAccordionDetails, FeebaAccordionSummary } from '../../component/PropertiesAccordion'
import { ActionTypes } from '../state/actions'
import { IState } from '../state/hooks'
import { PropertyContent } from './PropertyContent'
import { SurveyProperties } from './SurveyProperties'

export const PropertyColumn: FC<Props & BoxProps> = (props) => {
    const [isFolded, setIsFolded] = useState(false)
    const dispatch = useDispatch()

    const selection = useSelector((state: IState) => state.selection)

    const selectedComponent: SelectedComponent | null | undefined = useSelector((state: IState) => state.selectedComponent)

    const survey = useSelector((state: IState) => state.survey)

    const content: React.ReactNode = PropertyContent({
        currentSelection: selection.category || selection.categoryItem || null,
        selectedComponent,
        survey
    })
    const categoryItem = selection.categoryItem
    return (
        <Box
            sx={{
                height: '100%',
                minWidth: isFolded ? 0 : 300,
                width: isFolded ? 0 : 300,
                display: 'flex',
                position: 'relative',
                bgcolor: 'white',
                boxShadow: 1,
                p: isFolded ? 0 : 1,
                ...props.sx
            }}
        >
            <IconButton
                sx={{ position: 'absolute', left: -15, top: 30, backgroundColor: 'lightgrey', zIndex: 1 }}
                aria-label="delete"
                size="small"
                onClick={() => setIsFolded(!isFolded)}
            >
                {isFolded ? <KeyboardDoubleArrowLeft fontSize="inherit" /> : <KeyboardDoubleArrowRight fontSize="inherit" />}
            </IconButton>

            <Box sx={{ overflow: 'auto', width: '100%' }}>
                {categoryItem &&
                    (categoryItem?.type === ItemType.MultipleChoice ||
                        categoryItem?.type === ItemType.TextEntry ||
                        categoryItem.type === ItemType.MatrixTable ||
                        categoryItem.type === ItemType.NpsScore) && (
                        <Stack direction="column">
                            <FeebaAccordion defaultExpanded>
                                <FeebaAccordionSummary expandIcon={<ArrowRight />} id="panel1a-header">
                                    <Typography>Question type</Typography>
                                </FeebaAccordionSummary>
                                <FeebaAccordionDetails>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="select-item-type-label"
                                            id="select-item-type-label"
                                            fullWidth
                                            value={categoryItem.type}
                                            onChange={(event) =>
                                                dispatch({
                                                    type: ActionTypes.UPDATE_TYPE,
                                                    payload: {
                                                        categoryItem: {
                                                            ...categoryItem,
                                                            type: event.target.value as ItemType
                                                        }
                                                    }
                                                })
                                            }
                                        >
                                            <MenuItem value={ItemType.MultipleChoice}>Multiple Choice</MenuItem>
                                            <MenuItem value={ItemType.TextEntry}>Text entry</MenuItem>
                                            <MenuItem value={ItemType.NpsScore}>Nps Score</MenuItem>
                                            <MenuItem value={ItemType.MatrixTable}>Matrix table</MenuItem>
                                        </Select>
                                    </FormControl>
                                </FeebaAccordionDetails>
                            </FeebaAccordion>
                        </Stack>
                    )}
                {content}
                {selection.survey && (
                    <SurveyProperties
                        model={selection.survey}
                        onSurveyUpdate={(model) => {
                            dispatch({
                                type: ActionTypes.ITEM_UPDATE,
                                payload: {
                                    survey: model
                                }
                            })
                        }}
                    />
                )}
            </Box>
        </Box>
    )
}

export interface Props {
    onCategoryUpdated: () => void
}
