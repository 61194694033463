import { Answer, AnswerSelectionType, ItemType, MultipleChoiceQuestionUiModel } from '@feeba/types'
import { BoxProps, Checkbox, FormControl, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material'
import { FC, useState } from 'react'
import { FramelessEdit } from '../../component/FramelessEdit'
import { ActionTypes } from '../state/actions'
import { IState, surveyDispatcher, useCurrentSelection } from '../state/hooks'
import { SelectableItem } from './SelectableItem'

export const MultiChoiceComponent: FC<Props & BoxProps> = ({ data, sx, questionIndex, categoryId, index }) => {
    const [questionText, setQuestionText] = useState<string | null>(null)

    const dispatch = surveyDispatcher()
    const selectedItem = useCurrentSelection((state: IState) => {
        if (state.selection?.categoryItem?.type !== ItemType.Divider && state.selection?.categoryItem?.id === data.id) return state.selection?.categoryItem
        else return null
    })

    const setQuestionTextAction = (str: string) => {
        setQuestionText(str)
        //Use dispatch MULTICHOICE_UPDATE to update the name with index and type: ItemType.MultipleChoice
        if (str !== data.question.text) {
            dispatch({
                type: ActionTypes.ITEM_UPDATE,
                payload: { categoryItem: { ...data, question: { ...data.question, text: str } } }
            })
        }
    }

    const changeAnswerText = (answer: Answer, text: string) => {
        const newAnswers = data.answers.map((a: any) => {
            if (a.id === answer.id) {
                return { ...a, text: text }
            } else {
                return a
            }
        })
        dispatch({
            type: ActionTypes.ITEM_UPDATE,
            payload: { categoryItem: { ...data, answers: newAnswers } }
        })
    }

    // console.log('MultiChoiceComponent::Selected ', data.id, selectedItem?.id)
    return (
        <SelectableItem
            showAddButtons
            showRemoveButtons
            showBorder
            sx={sx}
            isSelected={data.id === selectedItem?.id}
            onAddAbove={() =>
                dispatch({
                    type: ActionTypes.ADD_QUESTION_ABOVE,
                    payload: {
                        currentQuestionIndex: questionIndex,
                        currentCategoryId: categoryId,
                        questionType: ItemType.MultipleChoice
                    }
                })
            }
            onAddBelow={() =>
                dispatch({
                    type: ActionTypes.ADD_QUESTION_BELOW,
                    payload: {
                        currentQuestionIndex: questionIndex,
                        currentCategoryId: categoryId,
                        questionType: ItemType.MultipleChoice
                    }
                })
            }
            onSelected={() =>
                dispatch({
                    type: ActionTypes.ITEM_SELECTED,
                    payload: { categoryItem: data }
                })
            }
            onRemove={() =>
                dispatch({
                    type: ActionTypes.REMOVE_QUESTION,
                    payload: { currentCategoryId: categoryId, selectionId: data.id, selectionIndex: questionIndex }
                })
            }
        >
            <Stack direction="column" sx={{ p: 1 }}>
                <FramelessEdit value={questionText || data.question.text} size="small" sx={{ pl: 2 }} onChange={(event) => setQuestionTextAction(event.target.value)} />
                <FormControl sx={{ pl: 2 }}>
                    <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={''} onChange={(event) => {}}>
                        {data.answers.map((answer, index) => {
                            return (
                                <FormControlLabel
                                    value={answer.id}
                                    key={index}
                                    control={data.answerType === AnswerSelectionType.Single ? <Radio size={'small'} /> : <Checkbox size={'small'} checked={false} />}
                                    label={
                                        <FramelessEdit
                                            sx={{ width: '100%' }}
                                            size="small"
                                            id="boardId"
                                            variant="outlined"
                                            onChange={(event) => changeAnswerText(answer, event.target.value)}
                                            value={answer.text}
                                        />
                                    }
                                />
                            )
                        })}
                    </RadioGroup>
                </FormControl>
            </Stack>
        </SelectableItem>
    )
}

export interface Props {
    data: MultipleChoiceQuestionUiModel
    questionIndex: number
    categoryId: string
    index: number
}
