import { ItemType, TextEntryQuestionUiModel } from '@feeba/types'
import { BoxProps, FormControl, Stack, TextField } from '@mui/material'
import { FC } from 'react'
import { SelectableItem } from '../builder/component/SelectableItem'
import { ActionTypes } from '../builder/state/actions'
import { IState, surveyDispatcher, useCurrentSelection } from '../builder/state/hooks'
import { FramelessEdit } from './FramelessEdit'

export const TextEntryComponent: FC<Props & BoxProps> = ({ data, sx, questionIndex, categoryId, index }) => {
    const dispatch = surveyDispatcher()
    const selectedItem = useCurrentSelection((state: IState) => {
        if (state.selection?.categoryItem?.type === ItemType.TextEntry && state.selection?.categoryItem?.id === data.id) return state.selection.categoryItem
        else return null
    })

    const updateTextEntryDataText = (str: string) => {
        if (str !== data.question.text) {
            data.answers.forEach((answer) => {
                answer.type = 'custom'
            })
            dispatch({
                type: ActionTypes.TEXTENTRY_UPDATE,
                payload: {
                    categoryItem: {
                        ...data,
                        question: { ...data.question, text: str },
                        answers: data.answers
                    }
                }
            })
        }
    }

    console.log('TextEntryComponent::Selected ', data.id, selectedItem?.id)
    return (
        <SelectableItem
            showAddButtons
            showRemoveButtons
            showBorder
            sx={sx}
            isSelected={data.id === selectedItem?.id}
            onAddAbove={() =>
                dispatch({
                    type: ActionTypes.ADD_QUESTION_ABOVE,
                    payload: {
                        currentQuestionIndex: questionIndex,
                        currentCategoryId: categoryId,
                        questionType: ItemType.MultipleChoice
                    }
                })
            }
            onAddBelow={() =>
                dispatch({
                    type: ActionTypes.ADD_QUESTION_BELOW,
                    payload: {
                        currentQuestionIndex: questionIndex,
                        currentCategoryId: categoryId,
                        questionType: ItemType.MultipleChoice
                    }
                })
            }
            onSelected={() =>
                dispatch({
                    type: ActionTypes.ITEM_SELECTED,
                    payload: { categoryItem: data }
                })
            }
            onRemove={() =>
                dispatch({
                    type: ActionTypes.REMOVE_QUESTION,
                    payload: {
                        currentCategoryId: categoryId,
                        selectionId: data.id,
                        selectionIndex: questionIndex
                    }
                })
            }
        >
            <Stack direction="column" sx={{ p: 1 }}>
                <FramelessEdit value={data.question.text} size="small" sx={{ pl: 2 }} multiline onChange={(event) => updateTextEntryDataText(event.target.value)} />
                <FormControl sx={{ pl: 2 }} style={{ marginTop: 5 }}>
                    <TextField
                        id={`readonly-text-enty-element-${index}`}
                        InputProps={{
                            readOnly: true
                        }}
                        multiline
                        style={{ padding: '0px !important', fontSize: 12, width: '70%' }}
                        onFocus={() => false}
                        disabled
                    />
                </FormControl>
            </Stack>
        </SelectableItem>
    )
}

export interface Props {
    data: TextEntryQuestionUiModel
    questionIndex: number
    categoryId: string
    index: number
}
