import { SurveyResult } from '@feeba/types'
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, tableCellClasses } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { FC, useEffect, useState } from 'react'
import { getSurveyTextResult } from '../../RestClient'

interface Props {
    surveyId: string
    surveyResult: SurveyResult
    language: string
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#00B2AF',
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

const formatDate = (date: Date): string => {
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    const year = date.getFullYear()
    let hour = date.getHours()
    const minute = date.getMinutes().toString().padStart(2, '0')
    const meridiem = hour >= 12 ? 'PM' : 'AM'
    hour = hour % 12 || 12 // Convert hour to 12-hour format
    const formattedDate = `${month}/${day}/${year} ${hour}:${minute}${meridiem}`
    return formattedDate
}

export const TextSurveyResult: FC<Props> = ({ surveyId, surveyResult, language }) => {
    const [pageSize, setPageSize] = useState(5)
    const [page, setPage] = useState(0)

    const [totalCount, setTotalCount] = useState(page)
    const [questionId, setQuestionId] = useState(surveyResult.question.questionId)
    const [result, setResults] = useState(surveyResult)

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageSize(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    useEffect(() => {
        console.log('Getting text results for surveyId: ', surveyId, ' page: ', page, ' pageSize: ', pageSize, 'questionId: ', questionId, ' language: ', language)

        console.log('SurveyResult: ', surveyResult)

        getSurveyTextResult(surveyId, questionId, language, page, pageSize)
            .then((e) => {
                console.log('getSurveyTextResult', e)
                setTotalCount(e.totalCount ?? 0)
                setResults(e)
            })
            .catch((ex) => {
                console.error('Error with getting surveyResults', ex)
            })
    }, [page, pageSize, language])

    return (
        <Box>
            {/* // <Typography variant="body1">{answer.text}</Typography> */}

            <TableContainer component={Paper} style={{ marginBottom: 20 }}>
                <Table aria-label="simple-text-field-table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Response</StyledTableCell>
                            <StyledTableCell align="right">Date</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {result.answers.map((answer) => (
                            <StyledTableRow key={answer.id}>
                                <StyledTableCell component="th" scope="row">
                                    {answer.text}
                                </StyledTableCell>
                                <StyledTableCell align="right">{answer.date ? formatDate(new Date(answer.date)) : ''}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[5, 10, 30]}
                component="div"
                count={totalCount}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    )
}
