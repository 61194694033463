import { CategoryModel, MessageEndModel } from '@feeba/types'
import { ArrowRight } from '@mui/icons-material'
import { BoxProps, Checkbox, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material'
import { FC, useState } from 'react'
import { FeebaAccordion, FeebaAccordionDetails, FeebaAccordionSummary } from '../../component/PropertiesAccordion'
import { ActionTypes } from '../state/actions'
import { surveyDispatcher } from '../state/hooks'

export const EndOfSurveyPropertyComponent: FC<Props & BoxProps> = ({ categoryModel }: Props) => {
    const dispatch = surveyDispatcher()

    const [isChecked, setIsChecked] = useState(categoryModel?.messageEnd?.visible)

    //update the survey model messageTextEnd visible used END_OF_SURVEY_UPDATE
    const updateSurveyModelMessageTextEnd = (visible: boolean) => {
        console.log('visible ---> ', visible)
        setIsChecked(visible)

        const messageEnd = categoryModel.messageEnd as MessageEndModel

        console.log('message End', messageEnd)

        dispatch({
            type: ActionTypes.UPDATE_CATEGORY,
            payload: {
                category: {
                    ...categoryModel,
                    messageEnd: {
                        ...messageEnd,
                        visible: visible
                    }
                }
            }
        })
    }

    return (
        <Stack direction="column">
            <FeebaAccordion defaultExpanded>
                <FeebaAccordionSummary expandIcon={<ArrowRight />} aria-controls="panel1a-content" id="format">
                    <Typography>End of survey message</Typography>
                </FeebaAccordionSummary>

                <FeebaAccordionDetails>
                    <Stack direction="column">
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox />}
                                label="Visible end of survey message"
                                checked={isChecked}
                                onChange={(e, checked) => updateSurveyModelMessageTextEnd(checked)}
                            />
                        </FormGroup>
                    </Stack>
                </FeebaAccordionDetails>
            </FeebaAccordion>
        </Stack>
    )
}

export interface Props {
    categoryModel: CategoryModel
}
