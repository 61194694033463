import { SurveyModel } from '@feeba/types'
import { Stack, StackProps } from '@mui/material'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { AddCategoryComponent } from '../component/AddCategoryComponent'
import { CategoryBlock } from '../component/CategoryBlock'
import { FramelessEdit } from '../component/FramelessEdit'
import { SelectableItem } from './component/SelectableItem'
import { ActionTypes } from './state/actions'
import { IState, surveyDispatcher } from './state/hooks'

export const SurveyColumnComponent: FC<Props & StackProps> = (props) => {
    const [surveyName, setSurveyName] = useState<string>(props.surveyToEdit.name)

    const dispatch = surveyDispatcher()
    const survey = useSelector((state: IState) => state.survey)
    const isSurveyBoxSelected = useSelector((state: IState) => {
        if (state.selection?.survey) return true
        else return false
    })

    // console.log('SurveyColumnComponent::Rendering', survey)
    return (
        <SelectableItem
            showRemoveButtons={false}
            showAddButtons={false}
            showBorder
            isSelected={isSurveyBoxSelected}
            onSelected={() =>
                dispatch({
                    type: ActionTypes.ITEM_SELECTED,
                    payload: { survey: survey }
                })
            }
        >
            <Stack sx={{ p: 0.5, height: '100%', ...props.sx }}>
                <FramelessEdit
                    sx={{ width: '100%' }}
                    id="boardId"
                    variant="outlined"
                    onChange={(event) => {
                        setSurveyName(event.target.value)
                        dispatch({
                            type: ActionTypes.SURVEY_BODY_UPDATE,
                            payload: {
                                survey: {
                                    ...survey,
                                    name: event.target.value
                                }
                            }
                        })
                    }}
                    value={surveyName}
                />
                {survey?.items.map((item, index) => {
                    if (!item) return <AddCategoryComponent key={index} onCategoryAddClicked={() => {}} />
                    return <CategoryBlock initialCategory={item} key={index} />
                })}
            </Stack>
        </SelectableItem>
    )
}

export interface Props {
    surveyToEdit: SurveyModel
    onSurveyAdded: () => void
}
