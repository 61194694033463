import { InlineConfig, ModalConfig, SurveyModel, SurveyType } from '@feeba/types'
import { InlineSurveyView } from '@feeba/web-sdk'
import { ArrowRight, QueuePlayNext } from '@mui/icons-material'
import { BoxProps, Button, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import { FC, useState } from 'react'
import { FeebaAccordion, FeebaAccordionDetails, FeebaAccordionSummary } from '../../component/PropertiesAccordion'
import { NextButtonConfigDialog } from './NextButtonConfigDialog'

export const SurveyProperties: FC<Props> = ({ model, onSurveyUpdate }) => {
    const [nextButtonDialog, showDialogAction] = useState(false)

    // console.log("SurveyProperties", model);
    return (
        <Stack direction="column">
            <FeebaAccordion defaultExpanded>
                <FeebaAccordionSummary expandIcon={<ArrowRight />} aria-controls="panel1a-content" id="format">
                    <Typography>Survey Type</Typography>
                </FeebaAccordionSummary>
                <FeebaAccordionDetails>
                    <FormControl fullWidth>
                        <RadioGroup
                            value={model.properties.typeConfig.type}
                            onChange={(event) => {
                                const type = event.target.value as SurveyType
                                let config: ModalConfig | InlineConfig
                                if (type === SurveyType.Modal) {
                                    config = { type: SurveyType.Modal }
                                } else {
                                    config = { type: SurveyType.Inline, surveyFlushPeriod: 2000 }
                                }
                                onSurveyUpdate({
                                    ...model,
                                    properties: { ...model.properties, typeConfig: config }
                                })
                            }}
                        >
                            <FormControlLabel value={SurveyType.Modal} control={<Radio size={'small'} />} label="Modal Survey" />
                            <FormControlLabel value={SurveyType.Inline} control={<Radio size={'small'} />} label="InView Survey" />
                        </RadioGroup>
                    </FormControl>
                    <Button startIcon={<QueuePlayNext />} fullWidth={true} onClick={() => showDialogAction(true)} style={{ justifyContent: 'flex-start' }}>
                        Next/Previous button text
                    </Button>
                </FeebaAccordionDetails>
            </FeebaAccordion>

            {nextButtonDialog && <NextButtonConfigDialog onClose={() => showDialogAction(false)} />}
            <Divider />
            <InlineSurveyView url="https://sv.feeba.io/s/feeba/65d7e7ecbb5dfdb932047e23" />
        </Stack>
    )
}

export interface Props extends BoxProps {
    model: SurveyModel
    onSurveyUpdate: (model: SurveyModel) => void
}
