import { AnswerSelectionType, TextEntryQuestionUiModel } from '@feeba/types'
import { ArrowRight } from '@mui/icons-material'
import { BoxProps, FormControl, MenuItem, Select, Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { FeebaAccordion, FeebaAccordionDetails, FeebaAccordionSummary } from '../../component/PropertiesAccordion'
import { surveyDispatcher } from '../state/hooks'

export const TextEntryProperties: FC<Props & BoxProps> = ({ model }) => {
    const dispatch = surveyDispatcher()

    return (
        <Stack direction="column">
            <FeebaAccordion defaultExpanded>
                <FeebaAccordionSummary expandIcon={<ArrowRight />} id="panel1a-header">
                    <Typography>Text type</Typography>
                </FeebaAccordionSummary>
                <FeebaAccordionDetails>
                    <FormControl>
                        <Select
                            labelId="select-type-text-entry"
                            id="select-type-text-entry"
                            fullWidth
                            size="small"
                            value={model.answerType}
                            // onChange={handleChange}
                        >
                            <MenuItem value={AnswerSelectionType.Single}>Single</MenuItem>
                            <MenuItem value={AnswerSelectionType.Multiple}>Multi line</MenuItem>
                        </Select>
                    </FormControl>
                </FeebaAccordionDetails>
            </FeebaAccordion>
        </Stack>
    )
}

export interface Props {
    model: TextEntryQuestionUiModel
}
