import { CategoryItem, CategoryModel, ItemType } from '@feeba/types'
import { adjustAnswers, generateMockQuestion, generateNewPageDivider } from '../Utils'
import { ActionTypes, RemoveQuestionItemAction, SelectionAction } from './actions'
import { IState } from './hooks'
import { getUpdatedCategoryItems } from './utils'

const reducer = (state: IState | undefined, action: SelectionAction): IState => {
    console.log('Reducer:: ', action.type, action.payload)
    if (!state) {
        throw new Error('State is undefined in the reducer')
    }

    const newState = { ...state }

    switch (action.type) {
        case ActionTypes.PAGE_BREAK_UPDATE:
            // Find the Page Break item and update it
            return {
                ...state,
                survey: {
                    ...state.survey,
                    items: state.survey.items.map((category) => {
                        const newItems: CategoryItem[] = Object.assign([], category.categoryItems)
                        if (category.id === action.payload.currentCategoryId) {
                            newItems[action.payload.pageBreakIndex] = action.payload.data
                        }
                        return {
                            ...category,
                            categoryItems: newItems
                        }
                    })
                }
            }
        case ActionTypes.REMOVE_QUESTION:
            // Find the CategoryItem and remove it
            return {
                ...state,
                survey: {
                    ...state.survey,
                    items: state.survey.items.map((category) => ({
                        ...category,
                        categoryItems:
                            category.id === action.payload.currentCategoryId
                                ? getUpdatedCategoryItems(category.categoryItems, action as RemoveQuestionItemAction)
                                : category.categoryItems
                    }))
                }
            }
        case ActionTypes.ITEM_UPDATE:
            const survey = { ...state.survey }
            if (action.payload.categoryItem) {
                const categoryItem = action.payload.categoryItem
                if (!categoryItem) throw new Error('CategoryItem not found for ActionTypes.QUESTION_UPDATE action')
                const updatedItems: Array<CategoryModel> =
                    state.survey?.items.map((category) => ({
                        ...category,
                        categoryItems: category.categoryItems.map((item) => {
                            if (item.type !== ItemType.Divider && categoryItem.type !== ItemType.Divider) {
                                return item.id === categoryItem.id ? categoryItem : item
                            } else {
                                return item
                            }
                        })
                    })) || []
                survey.items = updatedItems
            } else if (action.payload.category) {
                survey.items = survey.items.map((category) => {
                    if (category.id === action.payload.category?.id) return action.payload.category
                    else return category
                })
            } else {
                // Nothing to update, since survey is already updated when declared above
            }

            return {
                selection: action.payload,
                survey: action.payload.survey || survey,
                draft: true
            }
        case ActionTypes.SURVEY_BODY_UPDATE: {
            const newState = {
                ...state,
                survey: {
                    ...action.payload.survey,
                    name: action.payload.survey.name,
                    items: action.payload.survey.items
                },
                draft: true
            }
            return newState
        }
        case ActionTypes.UPDATE_TYPE:
            let updateTypeCurrentSelection = action.payload.categoryItem
            const updateTypeState = {
                ...state,
                survey: {
                    ...state.survey,
                    items: state.survey?.items.map((category) => ({
                        ...category,
                        categoryItems: category.categoryItems.map((item, index) => {
                            if (item.type !== ItemType.Divider && action.payload.categoryItem.type !== ItemType.Divider) {
                                let itemData = item as any
                                if (item.id === action.payload.categoryItem.id) {
                                    // We found the item that we want to update
                                    itemData = {
                                        ...generateMockQuestion(index, action.payload.categoryItem.type),
                                        question: item.question,
                                        answers: adjustAnswers(item.answers, updateTypeCurrentSelection.type)
                                    }
                                    updateTypeCurrentSelection = itemData
                                }
                                return itemData
                            } else {
                                return item
                            }
                        })
                    }))
                },
                currentSelection: updateTypeCurrentSelection,
                draft: true
            }
            return { ...updateTypeState }

        case ActionTypes.TEXTENTRY_UPDATE:
            const updatedTextEntryItems = state.survey?.items.map((category) => ({
                ...category,
                categoryItems: category.categoryItems.map((item) => {
                    if (item.type !== ItemType.Divider && action.payload.categoryItem.type !== ItemType.Divider) {
                        return item.id === action.payload.categoryItem.id ? action.payload.categoryItem : item
                    } else {
                        return item
                    }
                })
            }))
            return {
                selection: { categoryItem: action.payload.categoryItem },
                survey: {
                    ...state.survey,
                    items: updatedTextEntryItems
                },
                draft: true
            }
        case ActionTypes.NPS_SCORE_UPDATE:
            const updatedNpsScoreItems = state.survey?.items.map((category) => ({
                ...category,
                categoryItems: category.categoryItems.map((item) => {
                    if (item.type !== ItemType.Divider && action.payload.categoryItem.type !== ItemType.Divider) {
                        return item.id === action.payload.categoryItem.id ? action.payload.categoryItem : item
                    } else {
                        return item
                    }
                })
            }))
            return {
                selection: { categoryItem: action.payload.categoryItem },
                survey: {
                    ...state.survey,
                    items: updatedNpsScoreItems
                },
                draft: true
            }
        case ActionTypes.ADD_QUESTION_BELOW:
        case ActionTypes.ADD_QUESTION_ABOVE:
            const insertionIndex = action.payload.currentQuestionIndex

            // If the category is empty then we don't add a page divider
            const selectedCategory: CategoryModel | undefined = state.survey?.items.find((category) => category.id === action.payload.currentCategoryId)
            if (!selectedCategory) throw new Error('Category not found')

            let categoryItems: CategoryItem[] = selectedCategory.categoryItems

            if (selectedCategory.categoryItems.length === 0) {
                categoryItems = [generateMockQuestion(insertionIndex, action.payload.questionType)]
            } else if (action.type === ActionTypes.ADD_QUESTION_ABOVE) {
                categoryItems = [
                    ...selectedCategory.categoryItems.slice(0, insertionIndex),
                    generateMockQuestion(insertionIndex, action.payload.questionType),
                    generateNewPageDivider(),
                    ...selectedCategory.categoryItems.slice(insertionIndex)
                ]
            } else {
                if (insertionIndex === selectedCategory.categoryItems.length - 1) {
                    categoryItems = [...selectedCategory.categoryItems, generateNewPageDivider(), generateMockQuestion(insertionIndex + 1, action.payload.questionType)]
                } else {
                    categoryItems = [
                        ...selectedCategory.categoryItems.slice(0, insertionIndex + 1),
                        generateNewPageDivider(),
                        generateMockQuestion(insertionIndex + 1, action.payload.questionType),
                        ...selectedCategory.categoryItems.slice(insertionIndex + 1)
                    ]
                }
            }

            return {
                ...newState,
                survey: {
                    ...newState.survey,
                    items: newState.survey?.items.map((category) => ({
                        ...category,
                        categoryItems: category.id === action.payload.currentCategoryId ? categoryItems : category.categoryItems
                    }))
                },
                draft: true
            }
        case ActionTypes.NEXT_PREV_BUTTON_UPDATE:
            return {
                ...state,
                survey: {
                    ...state.survey,
                    nextButtonText: action.payload.nextButtonText,
                    prevButtonText: action.payload.prevButtonText
                },
                draft: true
            }
        case ActionTypes.UPDATE_CATEGORY:
            return {
                ...state,
                survey: {
                    ...state.survey,
                    items: state.survey.items.map((category) => {
                        if (category.id === action.payload.category.id) {
                            console.log('Category found: ', action.payload.category)
                            return action.payload.category
                        } else return category
                    })
                },
                draft: true
            }
        case ActionTypes.ITEM_SELECTED:
            return {
                ...state,
                selection: action.payload
            }
        default:
            return state
    }
}

export default reducer
