import { CategoryItem, CategoryModel, ItemType, MessageEndModel } from '@feeba/types'
import {
    Add,
    Apps,
    BorderColorOutlined,
    ChecklistOutlined,
    DragIndicatorSharp,
    KeyboardArrowDown,
    KeyboardArrowRight,
    MoreHoriz,
    StarBorderPurple500Rounded
} from '@mui/icons-material'
import { Button, Chip, Container, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Stack } from '@mui/material'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { generateEndMessage } from '../builder/Utils'
import { MatrixTableComponent } from '../builder/component/MatrixTableComponent'
import { MultiChoiceComponent } from '../builder/component/MultiChoiceComponent'
import { OptionalPageDividerComponent } from '../builder/component/OptionalPageDividerComponent'
import { SelectableItem } from '../builder/component/SelectableItem'
import { ActionTypes } from '../builder/state/actions'
import { IState, surveyDispatcher, useCurrentSelection } from '../builder/state/hooks'
import { defaultMessageEndOfSurvey } from '../builder/state/utils'
import { FramelessEdit } from './FramelessEdit'
import { NpsScoreComponent } from './NpsScoreComponent'
import { TextEntryComponent } from './TextEntryComponent'

export const CategoryBlock: FC<Props> = ({ initialCategory }) => {
    const [isFolded, setIsFolded] = useState(false)
    const dispatch = surveyDispatcher()
    const survey = useSelector((state: IState) => state.survey)

    const selectedItem = useCurrentSelection((state: IState) => {
        return state.selection
    })

    //Add Question popup menu related
    const [addQuestionMenuAnchorEl, setAddQuestionMenuAnchorEl] = React.useState<null | HTMLElement>(null)
    const messageEndElement: MessageEndModel | undefined = initialCategory.messageEnd ? initialCategory.messageEnd : undefined

    const open = Boolean(addQuestionMenuAnchorEl)
    const createQuestion = (temType: ItemType) => {
        let questionType: ItemType.MultipleChoice | ItemType.TextEntry | ItemType.MatrixTable | ItemType.NpsScore
        switch (temType) {
            case ItemType.MultipleChoice:
                questionType = ItemType.MultipleChoice
                break
            case ItemType.TextEntry:
                questionType = ItemType.TextEntry
                break
            case ItemType.MatrixTable:
                questionType = ItemType.MatrixTable
                break
            case ItemType.NpsScore:
                questionType = ItemType.NpsScore
                break
            default:
                // fail safe, fallback to MultipleChoice
                questionType = ItemType.MultipleChoice
                break
        }
        dispatch({
            type: ActionTypes.ADD_QUESTION_BELOW,
            payload: {
                currentCategoryId: initialCategory.id,
                currentQuestionIndex: initialCategory.categoryItems.length - 1,
                questionType: questionType
            }
        })
        setAddQuestionMenuAnchorEl(null)
    }

    const categoryItemSelected = (data: CategoryItem) => {
        dispatch({
            type: ActionTypes.ITEM_SELECTED,
            payload: { categoryItem: data }
        })
    }
    const addQuestionAbove = (categoryId: string, childIndex: number) => {
        dispatch({
            type: ActionTypes.ADD_QUESTION_ABOVE,
            payload: {
                currentQuestionIndex: childIndex,
                currentCategoryId: categoryId,
                questionType: ItemType.MultipleChoice
            }
        })
    }
    const addQuestionBelow = (categoryId: string, childIndex: number) => {
        dispatch({
            type: ActionTypes.ADD_QUESTION_BELOW,
            payload: {
                currentQuestionIndex: childIndex,
                currentCategoryId: categoryId,
                questionType: ItemType.MultipleChoice
            }
        })
    }

    const removeItem = (categoryId: string, itemId: string, index: number) => {
        dispatch({
            type: ActionTypes.REMOVE_QUESTION,
            payload: { currentCategoryId: categoryId, selectionId: itemId, selectionIndex: index }
        })
    }

    const updateMessageEndOfSurvey = (text: string) => {
        const messageEnd = initialCategory.messageEnd ? { ...initialCategory.messageEnd, value: text } : { ...generateEndMessage(), value: text }

        console.log(messageEnd)

        dispatch({
            type: ActionTypes.UPDATE_CATEGORY,
            payload: { category: { ...initialCategory, messageEnd } }
        })
    }

    // console.log('CategoryBlock::Rendering', initialCategory)
    return (
        <>
            <SelectableItem
                showRemoveButtons={false}
                showAddButtons={false}
                showBorder
                isSelected={selectedItem?.category?.id === initialCategory.id}
                onSelected={() => dispatch({ type: ActionTypes.ITEM_SELECTED, payload: { category: initialCategory } })}
            >
                <Stack
                    direction="column"
                    sx={{
                        bgcolor: 'white',
                        boxShadow: 1,
                        border: '1px solid',
                        borderRadius: 2,
                        p: 1,
                        borderColor: (theme) => (theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300')
                    }}
                >
                    <Stack direction="row" justifyContent={'space-between'}>
                        <IconButton sx={{ height: 40, width: 40 }} size="small" onClick={() => setIsFolded(!isFolded)}>
                            {isFolded ? <KeyboardArrowDown fontSize="inherit" /> : <KeyboardArrowRight fontSize="inherit" />}
                        </IconButton>
                        <FramelessEdit
                            sx={{ width: '100%' }}
                            size="small"
                            id="boardId"
                            variant="outlined"
                            // onChange={event => setCategory({...category, name: event.target.value})}
                            // value={category.name}
                        />
                        <IconButton aria-label="delete" size="small" onClick={() => setIsFolded(!isFolded)}>
                            <MoreHoriz fontSize="inherit" />
                        </IconButton>
                    </Stack>
                    {!isFolded &&
                        initialCategory.categoryItems.map((item, index, array) => {
                            if (item.type === ItemType.MultipleChoice) {
                                return <MultiChoiceComponent categoryId={initialCategory.id} key={index} index={index} data={item} sx={{ ml: 4 }} questionIndex={index} />
                            } else if (item.type === ItemType.TextEntry) {
                                return <TextEntryComponent categoryId={initialCategory.id} key={index} index={index} data={item} sx={{ ml: 4 }} questionIndex={index} />
                            } else if (item.type === ItemType.NpsScore) {
                                return <NpsScoreComponent categoryId={initialCategory.id} key={index} index={index} data={item} sx={{ ml: 4 }} questionIndex={index} />
                            } else if (item.type === ItemType.MatrixTable) {
                                return (
                                    <SelectableItem
                                        showAddButtons
                                        showRemoveButtons
                                        showBorder
                                        sx={{ ml: 4 }}
                                        key={index}
                                        isSelected={selectedItem?.categoryItem?.type === ItemType.MatrixTable && item.id === selectedItem?.categoryItem?.id}
                                        onAddAbove={() => addQuestionAbove(initialCategory.id, index)}
                                        onAddBelow={() => addQuestionBelow(initialCategory.id, index)}
                                        onSelected={() => categoryItemSelected(item)}
                                        onRemove={() => removeItem(initialCategory.id, item.id, index)}
                                    >
                                        <MatrixTableComponent
                                            enableEdit={true}
                                            key={index}
                                            initData={item}
                                            onUpdate={(updatedItem) => {
                                                dispatch({
                                                    type: ActionTypes.ITEM_UPDATE,
                                                    payload: { categoryItem: updatedItem }
                                                })
                                            }}
                                        />
                                    </SelectableItem>
                                )
                            } else if (item.type === ItemType.Divider) {
                                return <OptionalPageDividerComponent sx={{ ml: 4 }} key={index} data={item} categoryId={initialCategory.id} index={index} />
                            }
                        })}
                    {!isFolded && (
                        <Stack direction="row" justifyContent={'flex-end'} sx={{ p: 2 }}>
                            <Button variant={'outlined'} sx={{ textTransform: 'none' }} startIcon={<Apps />}>
                                Import from library
                            </Button>
                            <Button
                                aria-controls={open ? 'add-question-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                sx={{ ml: 2, textTransform: 'none' }}
                                variant="contained"
                                startIcon={<Add />}
                                onClick={(event) => setAddQuestionMenuAnchorEl(event.currentTarget)}
                            >
                                Add new question
                            </Button>
                            <Menu anchorEl={addQuestionMenuAnchorEl} open={open} onClose={() => setAddQuestionMenuAnchorEl(null)} id="add-question-menu" sx={{ width: '360px' }}>
                                <MenuList>
                                    <MenuItem onClick={() => createQuestion(ItemType.MultipleChoice)}>
                                        <ListItemIcon>
                                            <ChecklistOutlined fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Multi Choice</ListItemText>
                                    </MenuItem>
                                    <MenuItem onClick={() => createQuestion(ItemType.TextEntry)}>
                                        <ListItemIcon>
                                            <BorderColorOutlined fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Text entry</ListItemText>
                                    </MenuItem>
                                    <MenuItem onClick={() => createQuestion(ItemType.NpsScore)}>
                                        <ListItemIcon>
                                            <StarBorderPurple500Rounded fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Nps Score</ListItemText>
                                    </MenuItem>
                                    <MenuItem onClick={() => createQuestion(ItemType.MatrixTable)}>
                                        <ListItemIcon>
                                            <DragIndicatorSharp fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Matrix Table</ListItemText>
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </Stack>
                    )}

                    {!isFolded && messageEndElement && (
                        <Stack
                            direction="row"
                            sx={{
                                bgcolor: 'white',
                                boxShadow: 1,
                                border: '1px solid',
                                borderRadius: 2,
                                p: 1,
                                borderColor: (theme) => (theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300'),
                                opacity: messageEndElement?.visible ? 1 : 0.5
                            }}
                            justifyContent={'space-between'}
                        >
                            <Chip label="End of survey" />
                            <Container maxWidth="sm">
                                <FramelessEdit
                                    value={messageEndElement.value ? messageEndElement.value : defaultMessageEndOfSurvey}
                                    fullWidth
                                    multiline
                                    onChange={(event) => {
                                        updateMessageEndOfSurvey(event.target.value)
                                    }}
                                    inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                />
                            </Container>
                        </Stack>
                    )}
                </Stack>
            </SelectableItem>
        </>
    )
}

export type Props = { initialCategory: CategoryModel }
