import { FC } from 'react'

import { MultipleChoiceQuestionUiModel } from '@feeba/types'
import { Grid, TextField, Typography } from '@mui/material'

export const MultilanguageQuestionComponent: FC<Props> = ({ chageQuestionNameBySelectedLanguage, categoryItem, survey, categoryIndex, index }) => {
    const isEmpty = () => {
        console.log((survey?.items[index]?.categoryItems[categoryIndex] as MultipleChoiceQuestionUiModel)?.question?.text)
        const text = (survey?.items[index]?.categoryItems[categoryIndex] as MultipleChoiceQuestionUiModel)?.question?.text || null

        if (!text) {
            return true
        }
        return false
    }

    return (
        <>
            <Grid container xs={12} key={`mlqc-${categoryIndex}`} spacing={0}>
                <Grid item xs={6} style={{ background: '#fafafa', padding: '10px' }}>
                    <Typography variant="subtitle1" style={{ fontSize: '16px', fontWeight: 300 }}>
                        Base language
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: '10px' }}>
                        Question text{' '}
                    </Typography>
                    <TextField
                        fullWidth
                        label="Question"
                        name="question"
                        size="small"
                        variant="outlined"
                        value={categoryItem.question.text}
                        aria-readonly
                        style={{ marginTop: 5 }}
                    />
                </Grid>
                <Grid item xs={6} style={{ background: '#fff', padding: '10px' }}>
                    <Typography variant="subtitle1" style={{ fontSize: '16px', fontWeight: 300 }}>
                        Translation language{' '}
                    </Typography>

                    <Typography variant="body1" style={{ marginBottom: '10px' }}>
                        Question text{' '}
                    </Typography>

                    <TextField
                        color={isEmpty() ? 'warning' : 'primary'}
                        focused
                        fullWidth
                        label=""
                        name="question"
                        size="small"
                        variant="outlined"
                        onChange={(event) => {
                            chageQuestionNameBySelectedLanguage(event.target.value, index, categoryIndex)
                        }}
                        placeholder={categoryItem.question.text}
                        value={(survey?.items[index]?.categoryItems[categoryIndex] as MultipleChoiceQuestionUiModel)?.question?.text || ''}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export interface Props {
    chageQuestionNameBySelectedLanguage: (value: string, index: number, categoryIndex: number) => void
    categoryItem: MultipleChoiceQuestionUiModel
    survey: any
    categoryIndex: number
    index: number
}
