import { BipolarAnswerType, BipolarConfig, MatrixTableQuestionUiModel, MatrixType } from '@feeba/types'
import { AddRounded, AltRoute, ArrowRight, Edit, MoveDownRounded, RemoveRounded, Shuffle } from '@mui/icons-material'
import { BoxProps, Button, Divider, FormControlLabel, IconButton, MenuItem, Select, Stack, Switch, TextField, Typography } from '@mui/material'
import { FC, ReactNode, useState } from 'react'
import { FeebaAccordion, FeebaAccordionDetails, FeebaAccordionSummary } from '../../../component/PropertiesAccordion'
import { generateMockBlitzAnswer, generateMockStatement } from '../../Utils'
import { ActionTypes } from '../../state/actions'
import { surveyDispatcher } from '../../state/hooks'
import { MultiChoiceRandomizationDialog } from '../MultiChoiceRandomizationDialog'

export const MatrixTableProperties: FC<Props & BoxProps> = ({ model }) => {
    const [isRandomizerOpen, setIsRandomizerOpen] = useState<boolean>(false)
    const dispatch = surveyDispatcher()

    const updateModel = (model: MatrixTableQuestionUiModel) => {
        dispatch({
            type: ActionTypes.ITEM_UPDATE,
            payload: { categoryItem: model }
        })
    }
    const updateAnswers = (count: number) => {
        let answers = [...model.answers]
        if (count < answers.length) {
            // Cut the last element of the array
            answers.splice(count, answers.length - count)
        } else {
            for (let i = answers.length; i < count; i++) {
                answers.push(generateMockBlitzAnswer(i))
            }
        }
        updateModel({ ...model, answers })
    }
    const choiceCount = model.answers.length

    function getAnswerComponent(model: MatrixTableQuestionUiModel): ReactNode {
        let answerComponent: ReactNode = null
        switch (model.matrixType.type) {
            case MatrixType.Bipolar:
                const config: BipolarConfig = model.matrixType
                answerComponent = (
                    <FeebaAccordion defaultExpanded>
                        <FeebaAccordionSummary expandIcon={<ArrowRight />} id="panel1a-header">
                            <Typography>Answer type</Typography>
                        </FeebaAccordionSummary>
                        <FeebaAccordionDetails>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                fullWidth
                                size="small"
                                value={model.matrixType.answerType}
                                onChange={(e) => {
                                    const newModel: MatrixTableQuestionUiModel = {
                                        ...model,
                                        matrixType: { ...config, answerType: e.target.value as BipolarAnswerType }
                                    }
                                    updateModel(newModel)
                                }}
                            >
                                <MenuItem value={BipolarAnswerType.YesNo}>Yes | No</MenuItem>
                            </Select>
                        </FeebaAccordionDetails>
                    </FeebaAccordion>
                )
                break
            case MatrixType.Likert:
                break
        }
        return answerComponent
    }

    return (
        <Stack direction="column">
            {getAnswerComponent(model)}
            <FeebaAccordion defaultExpanded>
                <FeebaAccordionSummary expandIcon={<ArrowRight />} id="panel1a-header">
                    <Typography>Choices & Statements</Typography>
                </FeebaAccordionSummary>
                <FeebaAccordionDetails>
                    <Typography fontSize="small">Number of Choices</Typography>
                    <IconButton onClick={() => choiceCount > 2 && updateAnswers(choiceCount - 1)}>
                        {' '}
                        <RemoveRounded />{' '}
                    </IconButton>
                    <TextField
                        size="small"
                        InputProps={{ inputProps: { min: 0, max: 25 } }}
                        value={choiceCount}
                        variant="outlined"
                        sx={{ maxWidth: 48 }}
                        onChange={(event) => updateAnswers(parseInt(event.target.value))}
                    />
                    <IconButton onClick={() => choiceCount < 3 && updateAnswers(choiceCount + 1)}>
                        {' '}
                        <AddRounded />{' '}
                    </IconButton>
                    <Button disabled startIcon={<Edit />} fullWidth style={{ justifyContent: 'flex-start' }} size="small" sx={{ mt: 2 }}>
                        Edit multiple
                    </Button>
                    <FormControlLabel
                        labelPlacement="start"
                        sx={{ mt: 1, pr: 1, width: '100%' }}
                        label={<Typography sx={{ width: '100%' }}>Use suggested choices</Typography>}
                        control={<Switch size="small" />}
                    />
                    <Divider sx={{ m: 1 }} />
                    <Typography fontSize="small">Number of Statements</Typography>
                    <IconButton onClick={() => model.statements.length > 1 && updateModel({ ...model, statements: model.statements.slice(0, model.statements.length - 1) })}>
                        {' '}
                        <RemoveRounded />{' '}
                    </IconButton>
                    <TextField
                        size="small"
                        InputProps={{ inputProps: { min: 0, max: 25 } }}
                        value={model.statements.length}
                        variant="outlined"
                        sx={{ maxWidth: 48 }}
                        onChange={(event) => model.statements.length > 1 && updateModel({ ...model, statements: model.statements.slice(0, model.statements.length - 1) })}
                    />
                    <IconButton
                        onClick={() =>
                            updateModel({
                                ...model,
                                statements: model.statements.concat(generateMockStatement(model.statements.length + 1))
                            })
                        }
                    >
                        {' '}
                        <AddRounded />{' '}
                    </IconButton>
                </FeebaAccordionDetails>
            </FeebaAccordion>
            <FeebaAccordion defaultExpanded>
                <FeebaAccordionSummary expandIcon={<ArrowRight />} id="panel1a-header">
                    <Typography>Response requirements</Typography>
                </FeebaAccordionSummary>
                <FeebaAccordionDetails>
                    <FormControlLabel
                        labelPlacement="start"
                        sx={{ mt: 1, pr: 1, width: '100%' }}
                        label={<Typography sx={{ width: '100%' }}>Add requirements</Typography>}
                        control={<Switch size="small" checked={model.required} onChange={(event) => updateModel({ ...model, required: event.target.checked })} />}
                    />
                    <FormControlLabel
                        labelPlacement="start"
                        sx={{ mt: 1, pr: 1, width: '100%' }}
                        disabled
                        label={<Typography sx={{ width: '100%' }}>Add validation</Typography>}
                        control={<Switch size="small" />}
                    />
                </FeebaAccordionDetails>
            </FeebaAccordion>
            <FeebaAccordion defaultExpanded>
                <FeebaAccordionSummary expandIcon={<ArrowRight />}>
                    <Typography>Question behavior</Typography>
                </FeebaAccordionSummary>
                <FeebaAccordionDetails>
                    <Button disabled startIcon={<AltRoute />} fullWidth={true} style={{ justifyContent: 'flex-start' }}>
                        Display logic
                    </Button>
                    <Button disabled startIcon={<MoveDownRounded />} fullWidth={true} style={{ justifyContent: 'flex-start' }}>
                        Skip logic
                    </Button>
                    <Button startIcon={<Shuffle />} fullWidth={true} style={{ justifyContent: 'flex-start' }} onClick={() => setIsRandomizerOpen(true)}>
                        Choice randomization
                    </Button>
                </FeebaAccordionDetails>
            </FeebaAccordion>
            {isRandomizerOpen && (
                <MultiChoiceRandomizationDialog
                    model={model.answerRandomization}
                    open={true}
                    onClose={() => setIsRandomizerOpen(false)}
                    onOptionSelected={(option) => {
                        updateModel({ ...model, answerRandomization: option })
                        setIsRandomizerOpen(false)
                    }}
                />
            )}
        </Stack>
    )
}

export interface Props {
    model: MatrixTableQuestionUiModel
}
