import { SurveyModel } from '@feeba/types'
import { ArrowBack } from '@mui/icons-material'
import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import usePopup from '../../../../../popups/notifications/hook'
import { SurveyLanguageUiModel } from '../../../Models'
import { createSurveyLanguage, deleteSurveyLanguage, fetchSurvey, fetchSurveyLanguages, updateDefaultSurveyLanguage, upsertSurvey } from '../../../RestClient'
import { addInitialCategoryBlockIfEmpty } from '../Utils'
import { BaseLanguageComponent } from './BaseLanguageComponents'
import { MultiLangSideBySideEditor, SurveyTranslationPayload } from './MultiLangSideBySideEditor'
import { SurveyAddLanguageDialog } from './SurveyAddLanguageDialog'
import { SurveyGeneralList } from './SurveyGeneralList'
import { SurveyMultilanguageMenuComponent } from './SurveyMultilanguageMenuComponent'

export const SurveyMultilanguagePage: FC<Props> = ({ surveyId }) => {
    const { showNotification } = usePopup()
    const [pageState, setPageState] = useState<MultiLangPageState | null>()
    const [createLanguageDialogOpen, setCreateLanguageDialogOpen] = useState(false)

    const loadSurvey = async (lang: string, pageState: MultiLangPageState): Promise<SurveyModel> => {
        const result = await fetchSurvey(surveyId, lang)
        return result
    }

    const refreshPage = () => {
        fetchSurveyLanguages(surveyId)
            .then(async (result) => {
                // Pick the default language.
                let defaultLanguageModel = result.filter((e) => e.isDefault)?.[0]
                if (!defaultLanguageModel) {
                    // Fail Open: If default one is not found, pick the first one
                    defaultLanguageModel = result[0]
                }

                // fetch the default language survey
                const defaultLangSurvey = await fetchSurvey(surveyId, defaultLanguageModel.langCode)

                const newState: MultiLangPageState = {
                    languages: result,
                    defaultLanguageModel: defaultLanguageModel,
                    defaultLanguageSurvey: addInitialCategoryBlockIfEmpty(defaultLangSurvey),
                    selectedTranslation: null
                }
                setPageState(newState)
            })
            .catch((err) => {
                console.error('fetching', err)
                console.error('fetching', JSON.stringify(err, null, 2))
                showNotification({ text: err.message, type: 'error' })
            })
    }
    useEffect(() => {
        refreshPage()
    }, [surveyId])

    const createLanguageAction = (surveyLanguage: SurveyLanguageUiModel[]) => {
        console.log(surveyLanguage)

        createSurveyLanguage(surveyId, surveyLanguage)
            .then((result) => {
                setCreateLanguageDialogOpen(false)
                showNotification({ text: 'Language created', type: 'success' })
            })
            .catch((err) => {
                console.error('fetching', err)
                console.error('fetching', JSON.stringify(err, null, 2))
                showNotification({ text: err.message, type: 'error' })
            })
            .finally(() => {
                refreshPage()
            })
    }

    const deleteSurveyLanguageAction = (langCode: string) => {
        deleteSurveyLanguage(surveyId, langCode)
            .then((result) => {
                showNotification({ text: 'Language deleted', type: 'success' })
            })
            .catch((err) => {
                console.error('fetching', err)
                console.error('fetching', JSON.stringify(err, null, 2))
                showNotification({ text: err.message, type: 'error' })
            })
            .finally(() => {
                refreshPage()
            })
    }

    const choseLanguage = (langCode: string) => {
        if (!pageState) {
            showNotification({ text: 'Page state not found', type: 'error' })
            return
        }
        const langModel = pageState.languages.filter((e) => e.langCode === langCode)[0]
        if (!langModel) {
            showNotification({ text: 'Language not found', type: 'error' })
            return
        }

        loadSurvey(langCode, pageState).then((result) => {
            setPageState({
                ...pageState,
                selectedTranslation: {
                    langModel: langModel,
                    surveyModel: addInitialCategoryBlockIfEmpty(result)
                }
            })
        })
    }

    const saveSurveyEdit = () => {
        console.log('saveSurveyEdit::pageState=', pageState)
        const editedSurvey = pageState?.selectedTranslation
        if (!editedSurvey) {
            showNotification({ text: 'Survey not found', type: 'error' })

            return
        }

        setPageState(null)
        upsertSurvey(editedSurvey.surveyModel)
            .then(() => {
                showNotification({ text: 'Survey saved', type: 'success' })
            })
            .then(() => refreshPage())
            .catch((err) => {
                console.error('fetching', err)
                console.error('fetching', JSON.stringify(err, null, 2))
                showNotification({ text: err.message, type: 'error' })
            })
    }

    const updateSurveyDefaultLanguageAction = (langCode: string, label: string) => {
        updateDefaultSurveyLanguage({ surveyId, langCode, label })
            .then(() => {
                showNotification({
                    text: 'Survey default language updated',
                    type: 'success'
                })
            })
            .catch((err) => {
                console.error('fetching', err)
                console.error('fetching', JSON.stringify(err, null, 2))
                showNotification({ text: err.message, type: 'error' })
            })
            .finally(() => {
                refreshPage()
            })
    }

    console.log('pageState=', pageState)

    return (
        <Box sx={{ width: '100%' }}>
            {pageState && (
                <>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} style={{ backgroundColor: 'white' }} padding={2}>
                        <Grid item xs={12} sm={4} md={2}>
                            {!pageState.selectedTranslation && (
                                <SurveyMultilanguageMenuComponent
                                    languages={pageState.languages || []}
                                    choseLanguage={choseLanguage}
                                    setCreateLanguageDialogOpen={setCreateLanguageDialogOpen}
                                />
                            )}

                            {pageState.selectedTranslation && (
                                <Button
                                    onClick={() => setPageState({ ...pageState, selectedTranslation: null })}
                                    variant="text"
                                    fullWidth={true}
                                    style={{ justifyContent: 'left', color: '#32363a' }}
                                >
                                    <ArrowBack fontSize="small" style={{ textAlign: 'left' }} />

                                    <Typography textAlign={'left'} marginLeft={2}>
                                        {pageState.selectedTranslation?.langModel.label}
                                    </Typography>
                                </Button>
                            )}
                        </Grid>
                        <Divider orientation="vertical" flexItem sx={{ mr: '-1px' }} />

                        <Grid item xs={12} sm={8} md={10}>
                            {pageState.selectedTranslation && (
                                <MultiLangSideBySideEditor
                                    surveyId={surveyId}
                                    survey={pageState.selectedTranslation.surveyModel}
                                    selectedLanguage={pageState.selectedTranslation.langModel.langCode}
                                    defaultSurveyTranslation={{ surveyModel: pageState.defaultLanguageSurvey, langModel: pageState.defaultLanguageModel }}
                                    selectedLanguagLabel={pageState.selectedTranslation.langModel.label}
                                    updateCurrentLanguageSurvey={(survey) => {
                                        if (pageState.selectedTranslation) {
                                            setPageState({
                                                ...pageState,
                                                selectedTranslation: {
                                                    ...pageState.selectedTranslation,
                                                    surveyModel: survey
                                                }
                                            })
                                        }
                                    }}
                                    saveSurveyEdit={() => saveSurveyEdit()}
                                />
                            )}
                            {!pageState.selectedTranslation && (
                                <SurveyGeneralList languages={pageState.languages} choseLanguage={choseLanguage} deleteSurveyLanguageAction={deleteSurveyLanguageAction} />
                            )}

                            {!pageState.selectedTranslation && (
                                <Grid xs={12} sm={5} md={3} style={{ marginTop: '25px' }} item>
                                    <BaseLanguageComponent languages={pageState.languages || null} updateSurveyDefaultLanguageAction={updateSurveyDefaultLanguageAction} />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>

                    <SurveyAddLanguageDialog
                        setCreateLanguageDialogOpen={setCreateLanguageDialogOpen}
                        createLanguageDialogOpen={createLanguageDialogOpen}
                        createLanguageAction={createLanguageAction}
                        languages={pageState.languages || []}
                    />
                </>
            )}
            {!pageState && <CircularProgress />}
        </Box>
    )
}

export interface Props {
    surveyId: string
}

interface MultiLangPageState {
    languages: SurveyLanguageUiModel[]
    defaultLanguageModel: SurveyLanguageUiModel
    defaultLanguageSurvey: SurveyModel
    selectedTranslation: SurveyTranslationPayload | null
}
