import { Preview } from '@mui/icons-material'
import { Button, IconButton, Stack, Typography } from '@mui/material'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import usePopup from '../../../../../popups/notifications/hook'
import { upsertSurvey } from '../../../RestClient'
import { PreviewDialog } from '../../preview/PreviewDialog'
import { IState } from '../state/hooks'
import { SearchBar } from './SearchBar'

export const BuilderTopBar: FC = ({}) => {
    const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false)
    const survey = useSelector((state: IState) => state.survey)
    const stat = useSelector((state: IState) => state.draft)

    const { showNotification } = usePopup()

    return (
        <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'} sx={{ p: 0, width: '100%', bgcolor: '#fff' }}>
            {isPreviewDialogOpen && <PreviewDialog surveyModel={survey} isOpen={isPreviewDialogOpen} onClose={() => setIsPreviewDialogOpen(false)} />}
            <Stack direction={'row'} spacing={1} sx={{ width: '100%' }}>
                <Typography alignSelf={'center'}>Current (Default) language code: {survey.langCode} | </Typography>
                <Typography alignSelf={'center'}>Saved at: </Typography>
                {stat && (
                    <Typography alignSelf={'center'} sx={{ backgroundColor: 'lightgrey', pr: 1, pl: 1 }}>
                        Draft
                    </Typography>
                )}
            </Stack>
            <IconButton onClick={() => setIsPreviewDialogOpen(true)}>
                <Preview />
            </IconButton>
            <SearchBar />
            <Button
                variant={'outlined'}
                sx={{ mr: 1, pr: 5, pl: 5, height: '70%' }}
                onClick={() => {
                    upsertSurvey(survey)
                        .then(() => showNotification({ text: 'Survey saved', type: 'success' }))
                        .catch((e) => showNotification({ text: `Error: ${(e as Error).message}`, type: 'error' }))
                }}
            >
                Save
            </Button>
            <Button disabled variant={'contained'} sx={{ mr: 2, pr: 5, pl: 5, height: '70%' }} onClick={() => {}}>
                Publish
            </Button>
        </Stack>
    )
}
