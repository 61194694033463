import { Answer, CategoryItem, ItemType, MatrixTableQuestionUiModel, MultipleChoiceQuestionUiModel, SurveyModel } from '@feeba/types'
import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import { FC } from 'react'
import usePopup from '../../../../../popups/notifications/hook'
import { SurveyLanguageUiModel } from '../../../Models'
import { MatrixTableComponent } from '../component/MatrixTableComponent'
import { MultilanguageAnswerComponent } from './mulichoice/MultilanguageAnswerComponent'
import { MultilanguageQuestionComponent } from './mulichoice/MultilanguageQuestionComponent'
import { MultilanguageMessageEndComponent } from './other/MultilanguageMessageEndComponent'
import { MultilanguageNextButtonComponent } from './other/MultilanguageNextButtonComponent'
import { MultilanguageNpsScoreComponent } from './other/MultilanguageNpsScoreComponent'
import { MultilanguagePrevButtonComponent } from './other/MultilanguagePrevButtonComponent'
import { MultilanguageTextEntryComponent } from './other/MultilanguageTextEntryComponent'

export const MultiLangSideBySideEditor: FC<Props> = ({
    surveyId,
    survey,
    selectedLanguage,
    selectedLanguagLabel,
    defaultSurveyTranslation,
    updateCurrentLanguageSurvey,
    saveSurveyEdit
}: Props) => {
    const { showNotification } = usePopup()

    const chageQuestionNameBySelectedLanguage = (text: string, itemIndex: number, categoryIndex: number) => {
        console.log('chageQuestionNameBySelectedLanguage', text, itemIndex, categoryIndex)
        const newSurvey = { ...survey, id: surveyId }
        if (!newSurvey.items?.length) {
            showNotification({
                text: 'Items not found',
                type: 'error'
            })
            return
        }
        const item = newSurvey.items[itemIndex]
        if (!item) {
            showNotification({
                text: 'Item index not found',
                type: 'error'
            })
            return
        }
        const categoryItem = item.categoryItems[categoryIndex]
        if (!categoryItem) {
            showNotification({
                text: 'Category index not found',
                type: 'error'
            })
            return
        }
        if (categoryItem.type !== ItemType.MultipleChoice) {
            showNotification({
                text: 'Category type not found',
                type: 'error'
            })
            return
        }

        const question = categoryItem.question
        if (!question) {
            showNotification({
                text: 'Question not found',
                type: 'error'
            })
            return
        }
        const newQuestion = { ...question, text: text }
        const newCategoryItem = {
            ...categoryItem,
            question: newQuestion
        }
        const newItem = {
            ...item,
            categoryItems: [...item.categoryItems.slice(0, categoryIndex), newCategoryItem, ...item.categoryItems.slice(categoryIndex + 1)]
        }
        const newSurveyItems = [...newSurvey.items.slice(0, itemIndex), newItem, ...newSurvey.items.slice(itemIndex + 1)]
        const newSurveyResult = {
            ...newSurvey,
            items: newSurveyItems
        }
        console.log('newSurveyResult', newSurveyResult)
        updateCurrentLanguageSurvey(newSurveyResult as SurveyModel)
    }

    const changeTextEntryTextBySelectedLanguage = (text: string, itemIndex: number, categoryIndex: number) => {
        console.log('changeTextEntryTextBySelectedLanguage', text, itemIndex, categoryIndex)
        const newSurvey = { ...survey, id: surveyId }
        if (!newSurvey.items?.length) {
            showNotification({
                text: 'Items not found',
                type: 'error'
            })
            return
        }
        const item = newSurvey.items[itemIndex]
        if (!item) {
            showNotification({
                text: 'Item index not found',
                type: 'error'
            })
            return
        }
        const categoryItem = item.categoryItems[categoryIndex]
        if (!categoryItem) {
            showNotification({
                text: 'Category index not found',
                type: 'error'
            })
            return
        }
        if (categoryItem.type !== ItemType.TextEntry) {
            showNotification({
                text: 'Category type not found',
                type: 'error'
            })
            return
        }

        if (categoryItem.type === ItemType.TextEntry) {
            const textEntryData = categoryItem.question
            if (!textEntryData) {
                showNotification({
                    text: 'Question not found',
                    type: 'error'
                })
                return
            }
            const newTextEntryData = { ...textEntryData, text: text }
            const newCategoryItem = {
                ...categoryItem,
                question: newTextEntryData
            }
            const newItem = {
                ...item,
                categoryItems: [...item.categoryItems.slice(0, categoryIndex), newCategoryItem, ...item.categoryItems.slice(categoryIndex + 1)]
            }
            const newSurveyItems = [...newSurvey.items.slice(0, itemIndex), newItem, ...newSurvey.items.slice(itemIndex + 1)]
            const newSurveyResult = {
                ...newSurvey,
                items: newSurveyItems
            }
            console.log('newSurveyResult', newSurveyResult)
            updateCurrentLanguageSurvey(newSurveyResult as SurveyModel)
        }
    }

    const changeMessageEnd = (text: string, categoryIndex: number) => {
        console.log('changeMessageEnd', text, categoryIndex)
        const newSurvey = { ...survey, id: surveyId }
        if (!newSurvey.items?.length) {
            showNotification({
                text: 'Items not found',
                type: 'error'
            })
            return
        }
        const item = newSurvey.items[categoryIndex]
        if (!item) {
            showNotification({
                text: 'Item index not found',
                type: 'error'
            })
            return
        }

        const messageEndItem = newSurvey.items[categoryIndex].messageEnd

        const newEndMessageData = { ...messageEndItem, value: text }

        const newItem = {
            ...item,
            messageEnd: newEndMessageData
        }
        const newSurveyItems = [...newSurvey.items.slice(0, categoryIndex), newItem, ...newSurvey.items.slice(categoryIndex + 1)]
        const newSurveyResult = {
            ...newSurvey,
            items: newSurveyItems
        }
        console.log('newSurveyResult', newSurveyResult)
        updateCurrentLanguageSurvey(newSurveyResult as SurveyModel)
    }

    const changeNpsScoretBySelectedLanguage = (text: string, itemIndex: number, categoryIndex: number) => {
        console.log('changeNpsScoretBySelectedLanguage', text, itemIndex, categoryIndex)
        const newSurvey = { ...survey, id: surveyId }
        if (!newSurvey.items?.length) {
            showNotification({
                text: 'Items not found',
                type: 'error'
            })
            return
        }
        const item = newSurvey.items[itemIndex]
        if (!item) {
            showNotification({
                text: 'Item index not found',
                type: 'error'
            })
            return
        }
        const categoryItem = item.categoryItems[categoryIndex]
        if (!categoryItem) {
            showNotification({
                text: 'Category index not found',
                type: 'error'
            })
            return
        }
        if (categoryItem.type !== ItemType.NpsScore) {
            showNotification({
                text: 'Category type not found',
                type: 'error'
            })
            return
        }

        if (categoryItem.type === ItemType.NpsScore) {
            const npsScoreData = categoryItem.question
            if (!npsScoreData) {
                showNotification({
                    text: 'NpsScore not found',
                    type: 'error'
                })
                return
            }
            const newNpsScoreData = { ...npsScoreData, text: text }
            const newCategoryItem = {
                ...categoryItem,
                question: newNpsScoreData
            }
            const newItem = {
                ...item,
                categoryItems: [...item.categoryItems.slice(0, categoryIndex), newCategoryItem, ...item.categoryItems.slice(categoryIndex + 1)]
            }
            const newSurveyItems = [...newSurvey.items.slice(0, itemIndex), newItem, ...newSurvey.items.slice(itemIndex + 1)]
            const newSurveyResult = {
                ...newSurvey,
                items: newSurveyItems
            }
            console.log('newSurveyResult', newSurveyResult)
            updateCurrentLanguageSurvey(newSurveyResult as SurveyModel)
        }
    }

    const changeAnswerTextBySelectedLanguage = ({
        text,
        itemIndex,
        categoryIndex,
        answerIndex
    }: {
        text: string
        itemIndex: number
        categoryIndex: number
        answerIndex: number
    }) => {
        console.log('changeAnswerTextBySelectedLanguage', text, itemIndex, categoryIndex, answerIndex)
        const newSurvey = { ...survey, id: surveyId }
        if (!newSurvey.items?.length) {
            showNotification({
                text: 'Items not found',
                type: 'error'
            })
            return
        }
        const item = newSurvey.items[itemIndex]
        if (!item) {
            showNotification({
                text: 'Item index not found',
                type: 'error'
            })
            return
        }

        const categoryItem = item.categoryItems[categoryIndex] as MultipleChoiceQuestionUiModel

        if (!categoryItem) {
            showNotification({
                text: 'Category index not found',
                type: 'error'
            })
            return
        }
        if (categoryItem.type !== ItemType.MultipleChoice) {
            showNotification({
                text: 'Category type not found',
                type: 'error'
            })
            return
        }

        const answers = (defaultSurveyTranslation?.surveyModel.items[itemIndex]?.categoryItems[categoryIndex] as MultipleChoiceQuestionUiModel)?.answers

        const answer = categoryItem.answers[answerIndex] || answers[answerIndex]
        if (!answer) {
            showNotification({
                text: 'Answer not found',
                type: 'error'
            })
            return
        }
        const newAnswer = { ...answer, text: text }
        if (categoryItem.answers.length === 0) {
            categoryItem.answers = answers.map((el) => {
                return {
                    ...el,
                    text: ''
                }
            })
        }
        const newCategoryItem = {
            ...categoryItem,
            answers: [...categoryItem.answers.slice(0, answerIndex), newAnswer, ...categoryItem.answers.slice(answerIndex + 1)]
        }
        const newItem = {
            ...item,
            categoryItems: [...item.categoryItems.slice(0, categoryIndex), newCategoryItem, ...item.categoryItems.slice(categoryIndex + 1)]
        }
        const newSurveyItems = [...newSurvey.items.slice(0, itemIndex), newItem, ...newSurvey.items.slice(itemIndex + 1)]
        const newSurveyResult = {
            ...newSurvey,
            items: newSurveyItems
        }

        updateCurrentLanguageSurvey(newSurveyResult as SurveyModel)
    }
    const updateCategoryItem = (updatedItem: CategoryItem, categoryIndex: number, index: number) => {
        // create a newSurvey with updatedItem. Find the item in survey.items and replace it with updatedItem
        const newSurveyResult: SurveyModel = {
            ...survey
        }
        newSurveyResult.items[categoryIndex].categoryItems[index] = updatedItem
        console.log('newSurveyResult', newSurveyResult)
        updateCurrentLanguageSurvey(newSurveyResult)
    }

    const updateNextButtonText = (text: string) => {
        console.log('updateNextButtonText', text)

        const newSurvey = { ...survey, id: surveyId }

        const nextButtonText = { ...newSurvey.nextButtonText, value: text }

        const newSurveyResult = {
            ...newSurvey,
            nextButtonText: nextButtonText
        }

        updateCurrentLanguageSurvey(newSurveyResult as SurveyModel)
    }

    const updatePrevButtonText = (text: string) => {
        console.log('updatePrevButtonText', text)

        const newSurvey = { ...survey, id: surveyId }

        const prevButtonText = { ...newSurvey.prevButtonText, value: text }

        const newSurveyResult = {
            ...newSurvey,
            prevButtonText: prevButtonText
        }

        updateCurrentLanguageSurvey(newSurveyResult as SurveyModel)
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography variant="h6">Default language</Typography>
                    <Typography variant="body1">{defaultSurveyTranslation?.langModel.label}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h6">Language to fill in</Typography>
                    <Typography variant="body1">{selectedLanguagLabel}</Typography>
                </Grid>
            </Grid>
            <Divider sx={{ mt: 2, mb: 2 }} />

            {defaultSurveyTranslation?.surveyModel.items.map((category, categoryIndex) => {
                return (
                    <>
                        {category.categoryItems.map((categoryItem, categoryItemIndex) => (
                            <>
                                <Box sx={{ borderColor: 'text.primary', padding: 0 }} key={categoryItemIndex}>
                                    <Grid container xs={12} columns={2} spacing={0} item>
                                        {categoryItem.type === ItemType.MultipleChoice && (
                                            <>
                                                <MultilanguageQuestionComponent
                                                    chageQuestionNameBySelectedLanguage={chageQuestionNameBySelectedLanguage}
                                                    categoryItem={categoryItem}
                                                    survey={survey}
                                                    categoryIndex={categoryItemIndex}
                                                    index={categoryIndex}
                                                />

                                                {categoryItem.answers.map((answer: Answer, answerIndex: number) => {
                                                    return (
                                                        <MultilanguageAnswerComponent
                                                            changeAnswerTextBySelectedLanguage={changeAnswerTextBySelectedLanguage}
                                                            answerIndex={answerIndex}
                                                            answer={answer}
                                                            survey={survey}
                                                            categoryIndex={categoryItemIndex}
                                                            index={categoryIndex}
                                                            key={answerIndex}
                                                        />
                                                    )
                                                })}
                                            </>
                                        )}
                                        {categoryItem.type === ItemType.TextEntry && (
                                            <>
                                                <MultilanguageTextEntryComponent
                                                    changeTextEntryTextBySelectedLanguage={changeTextEntryTextBySelectedLanguage}
                                                    categoryItem={categoryItem}
                                                    survey={survey}
                                                    categoryIndex={categoryItemIndex}
                                                    index={categoryIndex}
                                                />
                                            </>
                                        )}
                                        {categoryItem.type === ItemType.NpsScore && (
                                            <>
                                                <MultilanguageNpsScoreComponent
                                                    changeNpsScoretBySelectedLanguage={changeNpsScoretBySelectedLanguage}
                                                    categoryItem={categoryItem}
                                                    survey={survey}
                                                    categoryIndex={categoryItemIndex}
                                                    index={categoryIndex}
                                                />
                                            </>
                                        )}
                                        {categoryItem.type === ItemType.MatrixTable && (
                                            <Grid container xs={12} key={`mlqc-${categoryItemIndex}`} spacing={0}>
                                                <Grid item xs={6} style={{ background: '#fafafa', padding: '10px' }}>
                                                    <MatrixTableComponent
                                                        enableEdit={false}
                                                        key={categoryIndex + '-org'}
                                                        initData={categoryItem}
                                                        sx={{ flexGrow: 1, width: '100%' }}
                                                        onUpdate={(updatedItem) => {}}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{ background: '#fafafa', padding: '10px' }}>
                                                    <MatrixTableComponent
                                                        initData={survey.items[categoryIndex].categoryItems[categoryItemIndex] as MatrixTableQuestionUiModel}
                                                        sx={{ flexGrow: 1, width: '100%' }}
                                                        enableEdit={true}
                                                        key={categoryIndex + '-translate'}
                                                        onUpdate={(updatedItem) => {
                                                            updateCategoryItem(updatedItem, categoryIndex, categoryItemIndex)
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}

                                        <Divider sx={{ mt: 3, mb: 3 }} />
                                    </Grid>
                                </Box>
                            </>
                        ))}

                        {<MultilanguageMessageEndComponent survey={survey} changeMessageEnd={changeMessageEnd} categoryModel={category} categoryIndex={categoryIndex} />}

                        {survey?.nextButtonText && (
                            <MultilanguageNextButtonComponent
                                changeNextButton={updateNextButtonText}
                                survey={survey}
                                defaultLanguageSurvey={defaultSurveyTranslation.surveyModel}
                            />
                        )}

                        {survey?.prevButtonText && (
                            <MultilanguagePrevButtonComponent
                                changePrevButton={updatePrevButtonText}
                                survey={survey}
                                defaultLanguageSurvey={defaultSurveyTranslation.surveyModel}
                            />
                        )}
                    </>
                )
            })}
            <Button style={{ margin: '20px 0 20px 0', fontSize: '18px' }} onClick={() => saveSurveyEdit()}>
                Save
            </Button>
        </>
    )
}

interface Props {
    surveyId: string
    survey: SurveyModel
    selectedLanguage: string
    selectedLanguagLabel: string
    defaultSurveyTranslation: SurveyTranslationPayload | null
    updateCurrentLanguageSurvey: (survey: SurveyModel) => void
    saveSurveyEdit: () => void
}

export interface SurveyTranslationPayload {
    langModel: SurveyLanguageUiModel
    surveyModel: SurveyModel
}
