import { FC } from 'react'

import { SurveyModel } from '@feeba/types'
import { Grid, TextField, Typography } from '@mui/material'

export const MultilanguageNextButtonComponent: FC<Props> = ({ changeNextButton, survey, defaultLanguageSurvey }) => {
    return (
        <>
            <Grid container xs={12} key={`mlqc-${'nextButton'}`} spacing={0}>
                <Grid item xs={6} style={{ background: '#fafafa', padding: '10px' }}>
                    <Typography variant="subtitle1" style={{ fontSize: '16px', fontWeight: 300 }}>
                        Base language
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: '10px' }}>
                        Next button{' '}
                    </Typography>
                    <TextField
                        fullWidth
                        label="Text"
                        name="npsScore"
                        size="small"
                        variant="outlined"
                        value={defaultLanguageSurvey.nextButtonText?.value}
                        aria-readonly
                        style={{ marginTop: 5 }}
                    />
                </Grid>
                <Grid item xs={6} style={{ background: '#fff', padding: '10px' }}>
                    <Typography variant="subtitle1" style={{ fontSize: '16px', fontWeight: 300 }}>
                        Translation language{' '}
                    </Typography>

                    <Typography variant="body1" style={{ marginBottom: '10px' }}>
                        NextButton{' '}
                    </Typography>

                    <TextField
                        color={!survey.nextButtonText?.value ? 'warning' : 'primary'}
                        focused
                        fullWidth
                        label=""
                        name="npsScore"
                        size="small"
                        variant="outlined"
                        onChange={(event) => {
                            changeNextButton(event.target.value)
                        }}
                        placeholder={defaultLanguageSurvey.nextButtonText?.value}
                        value={survey.nextButtonText?.value}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export interface Props {
    changeNextButton: (text: string) => void
    survey: SurveyModel
    defaultLanguageSurvey: SurveyModel
}
