import { Feeba } from '@feeba/web-sdk'
import BugReportIcon from '@mui/icons-material/BugReport'
import { IconButton, Stack, Tab, Tabs } from '@mui/material'
import { pink } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { TabValue } from './SurveyItemRootPage'

export const SurveyTopTabs: React.FC<Props> = ({ tabs }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const findCurrentTab = () => {
        const path = location.pathname.split('/')[3]
        // Check if any of the tavValues is in the pathname, if yes return the index of that tab, else return 0
        const index = tabs.findIndex((tab) => path === tab.path)
        return index === -1 ? 0 : index
    }
    const [tabIndex, setTabIndex] = useState(findCurrentTab)

    useEffect(() => {
        const tabIndex = findCurrentTab()
        setTabIndex(tabIndex)
    }, [location.pathname])

    return (
        <Stack id="topbar-parent" sx={{ backgroundColor: 'white' }} direction={'row'}>
            <Tabs
                sx={{ flexGrow: 1 }}
                textColor="inherit"
                indicatorColor="primary"
                value={tabIndex}
                onChange={(event, newValue) => {
                    setTabIndex(newValue)
                    navigate(tabs[newValue]?.path)
                }}
            >
                {tabs.map((tab, index) => (
                    <Tab key={index} label={tab.label} />
                ))}
            </Tabs>
            <IconButton onClick={() => Feeba.triggerEvent('survey_builder_bug_report')}>
                <BugReportIcon sx={{ color: pink[500] }} />
            </IconButton>
        </Stack>
    )
}

export interface Props {
    tabs: TabValue[]
}
