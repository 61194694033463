import { FC } from 'react'
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom'
import { SurveyBuilderRouterPage } from '../builder/SurveyBuilderRouterPage'
import { DistributionContainer } from '../distribution/DistributionContainer'
import { SurveyResultParent } from '../survey_result/SurveyResultParent'
import { SurveyTopTabs } from './SurveyTopTabs'

export interface TabValue {
    label: string
    path: string
    page: (surveyId: string) => JSX.Element
}

const tabs: TabValue[] = [
    { label: 'Survey Builder', path: 'builder', page: (surveyId) => <SurveyBuilderRouterPage surveyId={surveyId} /> },
    { label: 'Distribution', path: 'distribution', page: (surveyId) => <DistributionContainer surveyId={surveyId} /> },
    { label: 'Results', path: 'results', page: (surveyId) => <SurveyResultParent surveyId={surveyId} /> }
]

export const SurveyItemRootPage: FC = ({}) => {
    const { survey_id } = useParams() // see PrivateRoutes.tsx to understand this subpath

    return survey_id ? (
        <Routes>
            <Route
                element={
                    <>
                        <SurveyTopTabs tabs={tabs} />
                        <Outlet />
                    </>
                }
            >
                {tabs.map((tab) => (
                    <Route key={tab.path} path={tab.path + '/*'} element={tab.page(survey_id)} />
                ))}
                <Route path="*" element={<Navigate to={tabs[0].path} />} />
                <Route index element={<Navigate to={tabs[0].path} />} />
            </Route>
        </Routes>
    ) : (
        <Navigate to="/404" />
    )
}
