import { CategoryModel } from '@feeba/types'
import { ArrowRight, QueuePlayNext } from '@mui/icons-material'
import { BoxProps, Button, Stack, Typography } from '@mui/material'
import { FC, useState } from 'react'
import { FeebaAccordion, FeebaAccordionDetails, FeebaAccordionSummary } from '../../component/PropertiesAccordion'
import { EndOfSurveyPropertyComponent } from './EndOfSurveyProperties'
import { NextButtonConfigDialog } from './NextButtonConfigDialog'

export const CategoryProperties: FC<Props & BoxProps> = ({ model }) => {
    const [nextButtonDialog, showDialogAction] = useState(false)

    return (
        <Stack direction="column">
            <FeebaAccordion defaultExpanded>
                <FeebaAccordionSummary expandIcon={<ArrowRight />} aria-controls="panel1a-content" id="format">
                    <Typography>Format</Typography>
                </FeebaAccordionSummary>
                <FeebaAccordionDetails>
                    <Button startIcon={<QueuePlayNext />} fullWidth={true} onClick={() => showDialogAction(true)} style={{ justifyContent: 'flex-start' }}>
                        Next/Previous button text
                    </Button>

                    <EndOfSurveyPropertyComponent categoryModel={model} />
                </FeebaAccordionDetails>
            </FeebaAccordion>

            {nextButtonDialog && <NextButtonConfigDialog onClose={() => showDialogAction(false)} />}
        </Stack>
    )
}

export interface Props {
    model: CategoryModel
}
