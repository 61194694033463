import { FormControl, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { LANGUAGE_LIST, LangModel } from '../../../../../../utils/Langs'
import usePopup from '../../../../../popups/notifications/hook'
import { SurveyLanguageUiModel } from '../../../Models'

export const BaseLanguageComponent: FC<Props> = ({ languages, updateSurveyDefaultLanguageAction }) => {
    const [langCodeSet, updateLangCodeSet] = useState<Set<string>>(new Set())
    const [reorderedLangList, updateReorderedLangList] = useState<LangModel[]>([])
    const { showNotification } = usePopup()

    useEffect(() => {
        updateReorderedLangList(reoderedLangList(languages || []))
        updateLangCodeSet(new Set(languages?.map((el) => el.langCode)))
    }, [languages])

    const handleChange = (event: SelectChangeEvent) => {
        const {
            target: { value }
        } = event
        const label = LANGUAGE_LIST.find((el) => el.code === value)?.name
        if (!label) {
            showNotification({
                text: 'Language not found',
                type: 'error'
            })
            return
        }
        updateSurveyDefaultLanguageAction(value as string, label as string)
    }

    return (
        <>
            <Typography variant="h2" component="h2" fontSize={16} margin={'15px 0 10px 0'} padding={'0 15px 0 15px '}>
                Base language
            </Typography>
            <Typography variant="h2" component="h2" fontSize={16} margin={'15px 0 10px 0'} padding={'0 15px 0 15px '}>
                We recommend selecting the language you wrote your survey in. Your response data will also be displayed in this language.
            </Typography>

            <FormControl style={{ margin: '15px 0 10px 0', padding: '0 15px 0 15px' }} fullWidth={true}>
                <Select id="select-default-language" value={languages ? languages.find((el) => el.isDefault)?.langCode : ''} onChange={handleChange}>
                    {reorderedLangList.map((el, index) => {
                        const enabled = langCodeSet.has(el.code)
                        return (
                            <MenuItem key={index} value={el.code} disabled={!enabled} sx={{ color: enabled ? undefined : 'red' }}>
                                {el.name}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </>
    )
}

export interface Props {
    languages: SurveyLanguageUiModel[] | null
    updateSurveyDefaultLanguageAction(langCode: string, label: string): void
}

const reoderedLangList = (languages: SurveyLanguageUiModel[]): LangModel[] => {
    // Move the languages that are in the languages list to the top of the list and return the reordered list
    const removedLangs: LangModel[] = []
    const result = LANGUAGE_LIST.filter((el) => {
        const optionalFoundLanguage: SurveyLanguageUiModel | undefined = languages.find((lang) => lang.langCode === el.code)
        if (optionalFoundLanguage) {
            removedLangs.push(el)
            return false
        }
        return true
    })
    result.unshift(...removedLangs)
    return result
}
