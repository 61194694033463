import { Box, Stack, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import React, { useEffect, useRef, useState } from 'react'
import { SketchPicker } from 'react-color'

interface ColorSelectionButtonProps {
    color: string
    defaultColor: string
    onColorChange: (color: string) => void
}

const ColorSelectionButton: React.FC<ColorSelectionButtonProps> = ({ color, defaultColor, onColorChange }) => {
    const [showPicker, setShowPicker] = useState(false)
    const pickerRef = useRef<HTMLDivElement | null>(null)

    if (!defaultColor || defaultColor === '') {
        defaultColor = '#000000'
    }
    const [currentColor, setCurrentColor] = useState(defaultColor)

    const handleColorChange = (color: any) => {
        const newColor = color.hex
        setCurrentColor(newColor)
        onColorChange(newColor) // Call the callback with the new color
    }

    useEffect(() => {
        if (!showPicker) return
        const handleClickOutside = (event: MouseEvent) => {
            if (pickerRef.current && !pickerRef.current.contains(event.target as Node)) {
                setShowPicker(false)
            }
        }

        window.addEventListener('click', handleClickOutside)
        // console.log('ColorSelectionButton::useEffect::showPicker: ', showPicker);
        return () => {
            window.removeEventListener('click', handleClickOutside)
        }
    }, [showPicker])

    return (
        <Stack direction={'column'} sx={{ display: 'flex', height: '100%', my: 1 }}>
            <Typography align="left">{color === 'primary' ? 'Primary Color' : 'Secondary Color'}</Typography>

            <Box display="flex" flexDirection="row">
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: currentColor,
                        height: '35px',
                        width: '5px'
                    }}
                    onClick={(e) => {
                        e.stopPropagation()
                        setShowPicker(!showPicker)
                    }}
                ></Button>
                <Box mx={1}>
                    <TextField value={currentColor} sx={{ width: '100px', textAlign: 'left' }} />
                </Box>
            </Box>
            {showPicker && (
                <div ref={pickerRef}>
                    <SketchPicker color={currentColor} onChange={handleColorChange} />
                </div>
            )}
        </Stack>
    )
}

export default ColorSelectionButton
